<template>
  <div class="noteList">
    <el-divider content-position="left">
      <i class="fas fa-book book"></i>
      {{ $t("flashCards.Public technique") }}
    </el-divider>
    <Mnemonics :mnemonics="mnemonics" @saveLike="saveLike" />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import FlashCards from "@/apis/flashcards";
import Mnemonics from "@/views/flashCards/components/Mnemonics.vue";

export default {
  components: {
    Mnemonics
  },

  mixins: [],

  props: ["mnemonics"],
  data() {
    return {
    };
  },
  computed: {},
  watch: {},

  mounted() {
  },

  methods: {
    async saveLike(item) {
      if (item.liked_by_me === 1) {
        item.liked_by_me = 0;
        if (item.likes > 0) {
          item.likes = item.likes - 1;
        }
      } else {
        item.liked_by_me = 1;
        item.likes = item.likes + 1;
      }
      await FlashCards.saveLike(item.id);
      // this.getMnemonics();
    }
  }
};
</script>

<style scoped>
.noteList {
  padding: 0 15px;
}
.note-card {
  font-weight: 500;
  font-size: 12px;
  padding: 0 10px;
  display: flex;
  background-color: #ecf6f0;
  color: #42a16a;
  border-radius: 4px;
}
.note-star {
  text-align: center;
  margin: auto 0;
  width: 30px;
  font-size: 12px;
}
.note-star .thumbs {
  font-size: 20px;
}
.starHover {
  color: black;
  cursor: pointer;
}
.starIsSelected {
  color: orange;
  cursor: pointer;
}
::v-deep .el-divider__text.is-left {
  left: 0;
}
::v-deep .el-divider__text {
  padding-left: 0;
  color: #42a16a;
}
.note-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.note-content small {
  font-weight: normal;
  margin-top: 10px;
  display: inline-block;
}
</style>
