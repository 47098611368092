var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dis"},_vm._l((_vm.mnemonics),function(item,index){return _c('div',{key:index,staticClass:"mt-3 mb-2 note-card note-stared"},[(_vm.isMy)?[_c('div',{staticClass:"note-star pt-2 pb-2",staticStyle:{"font-size":"18px"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('editMyMnemonic', index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('div',[_c('el-divider',{attrs:{"direction":"vertical"}})],1)]:_vm._e(),_c('div',{staticClass:"note-content pt-2 pb-2"},[(
          item.mnemonic_img_url &&
            item.mnemonic_img_url !== '' &&
            _vm.isImage(item.mnemonic_img_url)
        )?_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('el-image',{attrs:{"src":item.mnemonic_img_url,"preview-src-list":[item.mnemonic_img_url]}})],1):_vm._e(),(item.mnemonic_img_url && item.mnemonic_img_url !== '')?_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(
            item.mnemonic_img_url &&
              item.mnemonic_img_url !== '' &&
              _vm.isVideo(item.mnemonic_img_url)
          )?_c('video',{staticClass:"my-video",attrs:{"src":item.mnemonic_img_url,"controls":""}}):_vm._e()]):_vm._e(),_c('div',[(_vm.checkUrl(item.mnemonic))?_c('a',{attrs:{"href":item.mnemonic,"target":"_blank"}},[_vm._v(" "+_vm._s(item.mnemonic)+"  ")]):_c('span',[_vm._v(" "+_vm._s(item.mnemonic)+"  ")]),(_vm.isMy)?[_c('small',{staticClass:"starIsSelected"},[_c('b',[_vm._v(" ("+_vm._s(_vm.$t("flashCards.Me"))+") ")])])]:[(item.is_hidden === 0)?[_c('small',{staticClass:"starIsSelected"},[_c('b',[_vm._v(" ("+_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name)+") ")])])]:_vm._e()]],2)]),_c('div',[_c('el-divider',{attrs:{"direction":"vertical"}})],1),_c('div',{staticClass:"note-star note-star-foot pt-2 pb-2",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('saveLike', item)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.liked_by_me === 1),expression:"item.liked_by_me === 1"}],staticClass:"thumbs"},[_c('i',{staticClass:"fas fa-thumbs-up starIsSelected"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.liked_by_me === 0),expression:"item.liked_by_me === 0"}],staticClass:"thumbs"},[_c('i',{staticClass:"far fa-thumbs-up"})]),_c('div',[_vm._v(_vm._s(item.likes + item.super_likes))])])],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }