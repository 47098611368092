<template>
  <div class="dis">
    <div
      class="mt-3 mb-2 note-card note-stared"
      v-for="(item, index) in mnemonics"
      :key="index"
    >
      <template v-if="isMy">
        <div
          class="note-star pt-2 pb-2"
          style="font-size:18px"
          @click.stop="$emit('editMyMnemonic', index)"
        >
          <i class="fas fa-edit"></i>
        </div>
        <div><el-divider direction="vertical"></el-divider></div>
      </template>
      <div class="note-content pt-2 pb-2">
        <div
          @click.stop=""
          v-if="
            item.mnemonic_img_url &&
              item.mnemonic_img_url !== '' &&
              isImage(item.mnemonic_img_url)
          "
        >
          <el-image
            :src="item.mnemonic_img_url"
            :preview-src-list="[item.mnemonic_img_url]"
          >
          </el-image>
        </div>
        <div
          @click.stop=""
          v-if="item.mnemonic_img_url && item.mnemonic_img_url !== ''"
        >
          <video
            class="my-video"
            v-if="
              item.mnemonic_img_url &&
                item.mnemonic_img_url !== '' &&
                isVideo(item.mnemonic_img_url)
            "
            :src="item.mnemonic_img_url"
            controls
          ></video>
        </div>
        <div>
          <a
            v-if="checkUrl(item.mnemonic)"
            :href="item.mnemonic"
            target="_blank"
          >
            {{ item.mnemonic }}&nbsp;
          </a>
          <span v-else> {{ item.mnemonic }}&nbsp; </span>
          <template v-if="isMy">
            <small class="starIsSelected">
              <b> ({{ $t("flashCards.Me") }}) </b>
            </small>
          </template>
          <template v-else>
            <template v-if="item.is_hidden === 0">
              <small class="starIsSelected">
                <b> ({{ item.user.first_name }} {{ item.user.last_name }}) </b>
              </small>
            </template>
          </template>
        </div>
      </div>
      <div><el-divider direction="vertical"></el-divider></div>
      <div
        class="note-star note-star-foot pt-2 pb-2"
        @click.stop="$emit('saveLike', item)"
      >
        <div class="thumbs" v-show="item.liked_by_me === 1">
          <i class="fas fa-thumbs-up starIsSelected"></i>
        </div>
        <div class="thumbs" v-show="item.liked_by_me === 0">
          <i class="far fa-thumbs-up"></i>
        </div>
        <div>{{ item.likes + item.super_likes }}</div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import mixins from "@/views/flashCards/mixins/index.js";

export default {
  metaInfo() {},

  components: {},

  mixins: [mixins],

  props: ["mnemonics", "isMy"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    checkUrl(link) {
      var reg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;
      if (!reg.test(link)) {
        return false;
      } else {
        return true;
      }
    },
    urltoFile(url, filename, mimeType) {
      return fetch(url)
        .then(function(res) {
          return res.arrayBuffer();
        })
        .then(function(buf) {
          return new File([buf], filename, { type: mimeType });
        });
    },
    resizeImage(img, maxWidth, maxHeight) {
      let newWidth = img.width;
      let newHeight = img.height;
      if (img.width > img.height && img.width > maxWidth) {
        newHeight = Math.floor(img.height * (maxWidth / img.width));
        newWidth = maxWidth;
      } else if (img.height > maxHeight) {
        newHeight = maxHeight;
        newWidth = Math.floor(img.width * (maxHeight / img.height));
      }
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      return canvas.toDataURL("image/jpeg");
    }
  }
};
</script>

<style scoped>
.my-video {
  margin-top: 20px;
  width: 400px;
}
.note-card {
  font-size: 18px;
  padding: 0 10px;
  color: #42a16a;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  width: 30rem;
  margin-right: 3rem;
}
.note-star {
  display: block;
  text-align: center;
  margin: auto 0;
  width: 30px;
  font-size: 18px;
}

.note-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  justify-content: center;
}

.note-content small {
  font-weight: normal;
  margin-top: 0;
  display: inline-block;
}
.starIsSelected {
  color: orange;
  cursor: pointer;
}

::v-deep .el-image:hover {
  opacity: 0.7;
}
::v-deep .el-image .el-image__inner,
::v-deep .el-image img {
  height: auto;
  max-height: 400px;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .note-star-foot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
  }
  .note-star-foot .thumbs {
    margin-right: 5px;
  }
  .note-star,
  .note-card {
    font-size: 24px;
  }
}
</style>
