<template>
  <el-dialog
    :title="$t('message.notice')"
    :visible.sync="visible"
    :before-close="() => $emit('cancel')"
    :width="width"
  >
    {{ $t("flashcards.resetDeleteVocabulary.message") }}
    <span slot="footer" class="dialog-footer">
      <el-button @click="() => $emit('cancel')">
        {{ $t("flashcards.resetDeleteVocabulary.cancel") }}
      </el-button>
      <el-button
        type="primary"
        @click="() => $emit('continue')"
      >
        {{ $t("flashcards.resetDeleteVocabulary.continue") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "90%"
    }
  }
};
</script>

<style lang="scss" scoped></style>
