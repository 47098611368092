/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {

  methods: {
    checkFile(fileName) {
      let index = fileName.lastIndexOf("."); //（考虑严谨用lastIndexOf(".")得到）得到"."在第几位
      let fileValueSuffix = fileName.substring(index); //截断"."之前的，得到后缀
      if (/(.*)\.(mp4|MP4|avi|AVI|wmv|WMV|mov|MOV)$/.test(fileValueSuffix)) {
        return "video";
      } else if (
        /(.*)\.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|HEIC|heic)$/.test(
          fileValueSuffix
        )
      ) {
        //根据后缀，判断是否符合图片格式
        return "image";
      } else if (
        /(.*)\.(xls|XLS|xlsx|XLSX|doc|DOC|docx|DOCX|pdf|PDF|PPT|PPTX|ppt|pptx)$/.test(
          fileValueSuffix
        )
      ) {
        //根据后缀，判断是否符合OFFICE格式
        return "office";
      }
      return false;
    },
    isImage(fileName){
      return this.checkFile(fileName) === "image";
    },
    isVideo(fileName){
      return this.checkFile(fileName) === "video";
    }
  }
};
